<template>
  <div class="vary-logo" :style="style">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 426.9 126.2"
      style="enable-background:new 0 0 426.9 126.2;"
      xml:space="preserve"
    >
      <g id="Layer_1" />
      <g id="logo">
        <path
          :style="'fill: ' + textColor"
          d="M21.6,3.8l31.3,63L84,3.8h21.8L60.8,97H45L0,3.8H21.6z"
        />
        <path
          :style="'fill: ' + textColor"
          d="M283.6,25.3L271.8,3.5c-10.1,2.8-19.4,9.5-26.6,17.6V3.5H217V97h28.2V81.5C245.4,53.2,258.2,32.7,283.6,25.3z"
        />
        <!-- eslint-disable -->
        <path
          :style="'fill: ' + textColor"
          d="M174.7,3.3v10.7c-8.5-8.9-18.2-13.3-28.9-13.3c-12.5,0-22.8,4.6-31.1,13.9c-8.3,9.2-12.4,20.8-12.4,34.6
            c0,14.1,4.1,25.9,12.4,35.3c8.4,9.2,18.9,13.9,31.7,13.9c10.1,0,19.6-4.1,28.4-12.3v9.7h25V3.3H174.7z M167.7,69.9
            c-4.6,5.1-10.6,7.7-18.1,7.7c-7,0-12.8-2.7-17.3-8c-4.5-5.2-6.7-12-6.7-20.4c0-7.8,2.3-14.4,6.9-19.7c4.7-5.2,10.4-7.9,17.3-7.9
            c7.3,0,13.3,2.6,17.9,7.8c4.6,5.3,6.9,12,6.9,20.2C174.6,57.8,172.3,64.5,167.7,69.9z"
        />
        <!-- eslint-enable -->
        <circle
          :style="'fill: ' + dotColor"
          cx="412"
          cy="81.7"
          r="15"
        />
        <polygon
          :style="'fill: ' + textColor"
          points="310.5,125.2 347.7,125.2 413.9,3.5 375.5,3.5 350.1,51.2 325.2,3.5 287.5,3.5 330.6,87"
        />
      </g>
    </svg>
  </div>
</template>

<script>
  export default {
    name: 'Logo',
    props: {
      width: {
        type: [
          Boolean,
          Number
        ],
        default: false
      },
      height: {
        type: [
          Boolean,
          Number
        ],
        default: false
      },
      textColor: {
        type: [String],
        default: '#501445'
      },
      dotColor: {
        type: [String],
        default: '#CC1337'
      }
    },
    data () {
      return {}
    },
    computed: {
      style () {
        let code = ''

        if (this.width) {
          code += `width: ${ this.width }px; `
        }

        if (this.height) {
          code += `height: ${ this.height }px; `
        }

        return code !== '' ? code : false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .st0 {
    fill:#501445;
  }
  .st1{
    fill:#CC1337;
  }
</style>
